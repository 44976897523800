import React from 'react'
import styles from './TangramSlider.module.scss'
import Slider from "react-slick";

const TangramSlider = ({data,view,stepHandler,setWhereActive,sliderorigin}) => {

  var settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    autoplay: true,
    autoplaySpeed: 6000,
    swipe:false,
    pauseOnFocus:false,
    pauseOnHover:false,
    
  };

  return data ?

    <div className={styles.slider} data-active={view === "reverse" ? "true" : "false"}>

      {data.kiosks[sliderorigin] ?
      
        <Slider {...settings} >

          {data.kiosks[sliderorigin].map((slide, key) => {
            
            return slide ? 

              <div key={key}>
                {slide.type === "image" && slide.image ? 
                
                  <div className={styles.item}>

                    <img src={slide.image.url} alt={slide.image.alt}/>

                    {slide.custom_title && slide.show_title ? 

                      <div className={styles.text} dangerouslySetInnerHTML={{__html:slide.custom_title}}/>
                  
                    : slide.store.title && slide.show_title ? 
                      <div className={styles.text} dangerouslySetInnerHTML={{__html:slide.store.title}}/>
                    :null}

                    {(slide.button_text && slide.store.map) || (slide.button_text && slide.store.multiple_map) ?

                      <div className={styles.button} onClick={()=>{stepHandler(slide.store);setWhereActive(false)}}>

                        <span dangerouslySetInnerHTML={{__html:slide.button_text}}/>

                      </div>
                    :null} 
                    
                  </div>

                :null}

                {slide.type === "video" && slide.video ? 
                
                  <div className={styles.item}>

                    <video controls={false} autoPlay={true} muted={true} loop={true}>

                      <source src={slide.video.url} type={slide.video.mime_type}/>
  
                    </video>
                    
                    {slide.custom_title && slide.show_title ? 

                      <div className={styles.text} dangerouslySetInnerHTML={{__html:slide.custom_title}}/>

                    : slide.store.title && slide.show_title ? 
                      <div className={styles.text} dangerouslySetInnerHTML={{__html:slide.store.title}}/>
                    :null}

                    {slide.button_text && slide.store.map ?

                      <div className={styles.button} onClick={()=>{stepHandler(slide.store);setWhereActive(false)}}>

                        <span dangerouslySetInnerHTML={{__html:slide.button_text}}/>

                      </div>
                    :null} 

                  </div>
                  
                :null}
              </div>

            :null
          })}

        </Slider>
      :null}
     
      
    </div>
    
  :null
}

export default TangramSlider