import React, {useState, useEffect} from 'react'
import StoreCard from '../../UI/StoreCard/StoreCard'
import styles from './Promotions.module.scss'



const Promotions = ({data,promoOpen, setPromoOpen,stepHandler}) => {

    const [promo, setPromo] = useState(null)

    useEffect(() => {
       
        if (data) {

            if (data.stores) {
                
                let adstores = data.stores.filter(store => {
                    return store.content.kiosks_promotion.show_on_kiosks_promotions && store.content.kiosks_ad
                })

                let noadstores = data.stores.filter(store => {
                    return store.content.kiosks_promotion.show_on_kiosks_promotions && !store.content.kiosks_ad
                })

                let storesFiltered = [...adstores, ...noadstores]

                if (storesFiltered) {

                    setPromo(storesFiltered)                
                }
            }
        }
        
    }, [data]);

  return (

    <div className={styles.promotions} data-open={promoOpen ? "true" : "false"}>

        <div className={styles.close} onClick={()=>{setPromoOpen(false)}}>

            <svg xmlns="http://www.w3.org/2000/svg" width="28.623" height="29.087" viewBox="0 0 28.623 29.087">
                <g id="Group_963" data-name="Group 963" transform="translate(-8208.865 -9293.441) rotate(45)">
                <line id="Line_107" data-name="Line 107" x2="37.478" transform="translate(12377.5 766.91)" fill="none" stroke="#000" strokeWidth="3"/>
                <line id="Line_108" data-name="Line 108" x2="37.478" transform="translate(12396.568 748.5) rotate(90)" fill="none" stroke="#000" strokeWidth="3"/>
                </g>
            </svg>

        </div>

        <h2>OUR PROMOTIONS</h2>

        {promo ? 

            <div className={styles.content}>

                {promo.map(store =>{

                    return(
        
                        <div className={styles.store} key={store.ID}>
            
                            <StoreCard store={store} type="promo" stepHandler={stepHandler}/>

                        </div>
                    )
                })}
            </div>

        :null}

    </div>
  )
}

export default Promotions