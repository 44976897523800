import React from 'react'
import styles from './StoreCard.module.scss'

const StoreCard = ({store,type,stepHandler}) => {


  return store ?

    <div className={styles.store}>

        <div className={styles.img}>

            {store.content.kiosks_ad ? 

                <div className={styles.ad}>

                <span>AD</span>

                </div>
            
            :null}

            {store.content.thumbnail ? 
                <img src={store.content.thumbnail.url} 
                alt={store.content.thumbnail.alt}/>
            : null}

        </div>

        <div className={styles.info}>

            {store.title ? 
                <div className={styles.title} dangerouslySetInnerHTML={{__html:store.title}}/>
            :null}

            {store.content.main_category ?
                <div className={styles.category} dangerouslySetInnerHTML={{__html:store.content.main_category.name}}/>
            :null}

            {store.content.short_description && type!== "promo"? 
                
                <div className={styles.description} dangerouslySetInnerHTML={{__html:store.content.short_description }}/>
            :null}

            {store.content.kiosks_promotion.description && type === "promo" ? 
                
                <div className={styles.promodescription} dangerouslySetInnerHTML={{__html:store.content.kiosks_promotion.description }}/>
            :null}

            <div className={styles.time}>

                {store.content.schedule && type !== "promo" ? 
                    <span dangerouslySetInnerHTML={{__html:store.content.schedule}} />
                :null}

                {store.content.kiosks_promotion.condition && type === "promo" ? 
                    <span dangerouslySetInnerHTML={{__html:store.content.kiosks_promotion.condition}} />
                :null}

            </div>

            {(store.content.map_location.floor !== "both" && store.map) || (store.content.map_location.floor === "both" && store.multiple_map) ? 

                <div className={styles.location} onClick={()=>{stepHandler(store)}}>

                    <svg width="11.163" height="15.411" viewBox="0 0 11.163 15.411">
                    <path d="M76.154,0a5.588,5.588,0,0,0-5.581,5.581c0,3.819,4.995,9.426,5.207,9.663a.5.5,0,0,0,.748,0c.213-.237,5.207-5.844,5.207-9.663A5.588,5.588,0,0,0,76.154,0Zm0,8.389a2.808,2.808,0,1,1,2.808-2.808A2.811,2.811,0,0,1,76.154,8.389Z" transform="translate(-70.573)"/>
                    </svg>

                    <span>TAKE ME THERE</span>

                </div> 

            :null}

        </div>
       
    </div>
  :null
}

export default StoreCard