import React, {useState,useEffect} from 'react'
import styles from './Search.module.scss'
import Keyboard from "react-simple-keyboard";
import StoreCard from '../../UI/StoreCard/StoreCard';

const Search = ({searchValue, setSearchValue, KeyboardOpen, setKeyboardOpen,data,view,stepHandler}) => {

    const [layout, setLayout] = useState("default")
    const [keyboardFunc, setKeyboardFunc] = useState()
    const [storeResult, setStoreResult] = useState()

    
    useEffect(() => {

       if (searchValue !== "" && data.stores) {
           let adresult = data.stores.filter(store => {
               let lowertitle = store.title.toLowerCase();
               return lowertitle.indexOf(searchValue.toLowerCase()) !== -1 && store.content.kiosks_ad
           })

           let noadresult = data.stores.filter(store => {
                let lowertitle = store.title.toLowerCase();
                return lowertitle.indexOf(searchValue.toLowerCase()) !== -1 && !store.content.kiosks_ad
            })

           let result = [...adresult, ...noadresult]
           setStoreResult(result)
       }
       else setStoreResult([]);

    }, [searchValue]);

    const onChange = input => {
        setSearchValue(input)
    };

    const onKeyPress = button => {
        if (button === "{shift}" || button === "{lock}") handleShift();
    };

    const handleShift = () => {
    
        setLayout(prevState => {
          return prevState === "default" ? "shift" : "default"
        });
    };

    const onChangeInput = event => {
        setSearchValue(event.target.value)
        keyboardFunc.setInput(event.target.value);
    };

  return (

    <div className={styles.container} data-open={KeyboardOpen ? "true" : "false"} data-active={view === "reverse" ? "true" : "false"}>

            <div className={styles.buttonClose} onClick={()=>{setKeyboardOpen(false)}}>

                <svg width="16.7" height="16.65" viewBox="0 0 16.7 16.65">
                    <path d="M8.35-16.65H3.7L.225-11.3-3.2-16.65H-8.175l5.7,8.325L-8.3,0h4.65l3.6-5.45L3.45,0H8.4L2.65-8.475Z" transform="translate(8.3 16.65)"/>
                </svg>

            </div>

            <div className={styles.overlay} onClick={()=>{setKeyboardOpen(false)}}></div>

            <h2>SEARCH</h2>

            <div className={styles.searchContainer}>

                <svg viewBox="0 0 38.774 40.161">
                    <path d="M39.169,36.581,29.61,26.639A16.209,16.209,0,1,0,17.2,32.43a16.042,16.042,0,0,0,9.29-2.934L36.12,39.513a2.115,2.115,0,1,0,3.048-2.932ZM17.2,4.23A11.985,11.985,0,1,1,5.214,16.215,12,12,0,0,1,17.2,4.23Z" transform="translate(-0.984)"/>
                </svg>

                <input type="text" value={searchValue} onChange={onChangeInput}></input>

            </div>

            <div className={styles.Keyboard}>

                <Keyboard
                    keyboardRef={r => {setKeyboardFunc(r)}}
                    theme={"hg-theme-default myTheme1"}
                    layoutName={layout}
                    onChange={input => onChange(input)}
                    onKeyPress={button => onKeyPress(button)}
                />

            </div>

            {storeResult ? 

                <div className={styles.result} data-active={view === "reverse" ? "true" : "false"}>
                    {storeResult.map(store => {
                        return (
                            <div className={styles.store} key={store.ID}>
                                <StoreCard store={store} stepHandler={stepHandler}/>
                            </div>
                        )

                    })}
                </div>
            :null}
    </div>   
    
  )
}

export default Search