import React from 'react'
import styles from './Filter.module.scss'
import IconButton from '../../UI/IconButton/IconButton'



const Filters = ({view, setView, setKeyboardOpen,data,activeCategories, setActiveCategories, setHardReset,setPromoOpen,setSteps,setWhereActive}) => {

    const categoryHandler = (cat) => {
        let newCategories = activeCategories;
        const index = newCategories.indexOf(cat);
        if (index !== -1) newCategories.splice(index, 1);
        else newCategories.push(cat)
        setActiveCategories(newCategories);
        setHardReset(prevState => {return !prevState});
        setWhereActive(false)
    }

  return (

    <div className={styles.container} data-active={view === "reverse" ? "true" : "false"}>

        <div className={styles.content}>

            <h1>DIRECTORY</h1>

            <div className={styles.col1}>

                <div className={styles.search}>

                    <div className={styles.searchContainer} onClick={()=>{setKeyboardOpen(true);setSteps(0);setWhereActive(false)}}>

                        <svg viewBox="0 0 38.774 40.161">
                            <path d="M39.169,36.581,29.61,26.639A16.209,16.209,0,1,0,17.2,32.43a16.042,16.042,0,0,0,9.29-2.934L36.12,39.513a2.115,2.115,0,1,0,3.048-2.932ZM17.2,4.23A11.985,11.985,0,1,1,5.214,16.215,12,12,0,0,1,17.2,4.23Z" transform="translate(-0.984)"/>
                        </svg>

                        <input type="text" placeholder='SEARCH'></input>

                    </div>

                </div>

                <div className={styles.categories}>        

                    {data ?
                        data.categories ?
                            <>
                                <div className={styles.category} data-active={activeCategories.length>0 ? "false" : "true"} onClick={()=>{setActiveCategories([])}} >
                                    <span>ALL</span>
                                </div>
                                {data.categories.map(category => {
                                   
                                    return (

                                        <div 
                                            className={styles.category} 
                                            key={category.term_id} 
                                            data-active={activeCategories.includes(category.slug)? "true" : "false"} 
                                            onClick={()=>{categoryHandler(category.slug)}}
                                        >

                                            <span dangerouslySetInnerHTML={{__html:category.name}}/>

                                        </div>
                                    )
                                })}
                            </>
                        
                        : null
                    
                    :null}

                </div>

            </div>

            <div className={styles.col2}>

                <IconButton text="Map View" icon="map" active={view === "map" || view ==="reverse" ? true : false} onClick={()=>{setView("map")}}/>
                <IconButton text="List View" icon="list" active={view === "list" ? true : false} onClick={()=>{setView("list");setWhereActive(false)}}/>
                <IconButton text="Promotions" icon="promo" onClick={()=>{setPromoOpen(true);setWhereActive(false)}}/>
                
            </div>
        </div>
    </div>
  )
}

export default Filters