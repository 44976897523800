import React from 'react'
import styles from './IconButton.module.scss'
import IconPromo from '../../assets/IconPromo.svg'

const IconMap = () => {

    return (

        <svg viewBox="0 0 18.79 25.942" className={styles.map}>
            <g transform="translate(-70.573)">
                <g transform="translate(70.573)">
                    <path d="M79.968,0a9.406,9.406,0,0,0-9.4,9.4c0,6.429,8.408,15.867,8.766,16.266a.846.846,0,0,0,1.259,0c.358-.4,8.766-9.837,8.766-16.266A9.406,9.406,0,0,0,79.968,0Zm0,14.122A4.727,4.727,0,1,1,84.7,9.4,4.732,4.732,0,0,1,79.968,14.122Z" transform="translate(-70.573)"/>
                </g>
            </g>
        </svg>

    )
}

const IconList = () => {

    return (

        <svg viewBox="0 0 28.542 21.484" className={styles.list}>

            <g transform="translate(0 -7.434)">

                <path d="M34.43,48.741H15.344a1.424,1.424,0,1,1,0-2.848H34.43a1.424,1.424,0,0,1,0,2.848Z" transform="translate(-7.312 -20.201)"/>
                <path d="M34.43,29.91H15.344a1.424,1.424,0,1,1,0-2.848H34.43a1.424,1.424,0,0,1,0,2.848Z" transform="translate(-7.312 -10.31)"/>
                <path d="M34.43,11.079H15.344a1.424,1.424,0,1,1,0-2.848H34.43a1.424,1.424,0,0,1,0,2.848Z" transform="translate(-7.312 -0.419)"/>
                <circle cx="1.913" cy="1.913" r="1.913" transform="translate(0 7.434)"/>
                <circle cx="1.913" cy="1.913" r="1.913" transform="translate(0 16.263)"/>
                <circle cx="1.913" cy="1.913" r="1.913" transform="translate(0 25.093)"/>
                
            </g>

        </svg>


    )
}


const IconButton = ({text, icon, active, onClick}) => {

  return (

    <div className={styles.button} onClick={onClick ? onClick : null} data-active={active ? "true" : "false"}>

        <div className={styles.icon}>

            {icon === "map" ? <IconMap/> :null}
            {icon === "list" ? <IconList/> :null}
            {icon === "promo" ? <img src={IconPromo} alt='icon promo'/> :null}

        </div>


        <span>{text}</span>

    </div>
  )
}

export default IconButton