import React, {useState,useEffect} from 'react'
import './App.css';
import styles from './App.module.scss';
import Header from './components/Header/Header';
import Map from './components/Map/Map';
import Filters from './components/Filters/Filters';
import TangramSlider from './components/Slider/TangramSlider';
import Search from './components/Search/Search';
import axios from 'axios'
import List from './components/List/List';
import Promotions from './components/Promotions/Promotions';
import Loader from './UI/Loader/Loader';



function App({location, position}) {

  const [view, setView] = useState("map");
  const [searchValue, setSearchValue] = useState("");
  const [KeyboardOpen, setKeyboardOpen] = useState(false);
  const [data, setData] = useState(null);
  const [activeCategories, setActiveCategories] = useState([]);
  const [hardReset, setHardReset] = useState(false);
  const [activeStores, setActiveStores] = useState(null);
  const [promoOpen, setPromoOpen] = useState(false);
  const [clickedStore, setClickedStore] = useState(null);
  const [whereActive, setWhereActive] = useState(false);
  const [loader, setLoader] = useState(true);
  const [steps, setSteps] = useState(0);
  const [currentMap,setCurrentMap] = useState("ground")


  useEffect(() => {
    let isCancelled = false;

    const getData = () => {
      axios.get(process.env.REACT_APP_API_SERVER + "kiosks/data")
        .then(response => {
            if (response.status === 200) {
              setData(response.data)
              setActiveStores(response.data.stores)
              setCurrentMap(response.data.kiosks[location].floor)
            }
            setLoader(false)
        })
        .catch(error => {console.log(error)})
    }

    if (!isCancelled) {
      getData()
    }

    return () => {
      isCancelled = true;
    };
  }, []);

  const clickedStoreHandler = (id) => {
    if (data) {

      if (id === "clear" ) {
        setClickedStore(null)
        
      } else {

        const selectedStore = data.stores.filter(store => {
          return store.ID === id
        })

        if (currentMap === "food") setCurrentMap("first")
  
        setClickedStore(selectedStore[0])
      }
      
    }
  }

  const stepHandler = (store) => {
    

    if (store.content.map_location.floor === data.kiosks[location].floor || store.content.map_location.floor === "both") {
      setSteps(0)
    }
    else{
      setSteps(1)
    }
    setCurrentMap(data.kiosks[location].floor)
    setView("map")
    setClickedStore(store)
    setPromoOpen(false)
    setKeyboardOpen(false)

  }

  return (
   
      <div className={styles.App}>

        <Loader active={loader}/>
    
        <Header/>

        {view === "reverse" && !position ? <TangramSlider sliderorigin="slider" data={data} view={view} stepHandler={stepHandler} setWhereActive={setWhereActive}/> :null}

        <Filters 
          view={view} 
          setView={setView} 
          setKeyboardOpen={setKeyboardOpen} 
          data={data}
          activeCategories={activeCategories}
          setActiveCategories={setActiveCategories}
          setHardReset={setHardReset}
          setPromoOpen={setPromoOpen}
          setSteps={setSteps}
          setWhereActive={setWhereActive}
        />

        <div className={styles.layout}>

          {view === "list" ? 

            <List activeStores={activeStores} 
                  setActiveStores={setActiveStores} 
                  data={data}  
                  activeCategories={activeCategories} 
                  setHardReset={setHardReset}
                  steps={steps}
                  setSteps={setSteps}
                  stepHandler={stepHandler}
            /> 
          :null}

          {view === "map" || view === "reverse" ? 

          <Map 
            data={data} 
            setView={setView} 
            view={view} 
            activeCategories={activeCategories} 
            clickedStoreHandler={clickedStoreHandler} 
            clickedStore={clickedStore}
            whereActive={whereActive}
            setWhereActive={setWhereActive}
            currentMap={currentMap}
            setCurrentMap={setCurrentMap}
            steps={steps}
            setSteps={setSteps}
            setActiveCategories={setActiveCategories}
            setHardReset={setHardReset}
            location={location}
          /> 
          :null}

          {position === "landscape" ? 

            <div className={styles.landscapeSliders}>

              <div className={styles.slider1}>
                <TangramSlider sliderorigin="landscape_slider_1" data={data} view={view} stepHandler={stepHandler} setWhereActive={setWhereActive}/>
              </div>

              <div className={styles.slider2}>
                <TangramSlider sliderorigin="landscape_slider_2" data={data} view={view} stepHandler={stepHandler} setWhereActive={setWhereActive}/>
              </div>

            </div>
                    
          :null}

        </div>
        
        {view === "map" && !position ? <TangramSlider sliderorigin="slider" data={data} view={view} stepHandler={stepHandler} setWhereActive={setWhereActive}/> :null}

        <Search 
          searchValue={searchValue} 
          setSearchValue={setSearchValue} 
          KeyboardOpen={KeyboardOpen} 
          setKeyboardOpen={setKeyboardOpen}
          data={data}
          view={view}
          steps={steps}
          setSteps={setSteps}
          stepHandler={stepHandler}
        />

        <Promotions data={data} promoOpen={promoOpen} setPromoOpen={setPromoOpen} stepHandler={stepHandler}/>

      </div>
  );
}

export default App;
