import React,{useEffect,useState} from 'react'
import StoreCard from '../../UI/StoreCard/StoreCard';
import styles from './List.module.scss'

const List = ({
        activeStores, 
        setActiveStores,
        data,
        activeCategories,
        setHardReset,
        steps, 
        setSteps,
        stepHandler
}) => {
  
    const [paginator, setpaginator] = useState(1)
    const cardstoshow = window.innerWidth > window.innerHeight ? 12 : 15;

    useEffect(() => {
        
        if (activeCategories.length > 0) {
            let newstores = data.stores.filter(store => {
                let storeflag = false;

                if (store.categories) {
                    store.categories.map(category => {
                        if(activeCategories.includes(category.slug)) storeflag = true;
                        return false;
                    })
                }

                return storeflag
            })

            setActiveStores(newstores)
        }
        else {
            setActiveStores(data.stores)
        }

        setActiveStores(prevState => {

            
            let adstores = prevState.filter(store => {return store.content.kiosks_ad})
            let noadstores = prevState.filter(store => {return !store.content.kiosks_ad})

            return [...adstores, ...noadstores]
        })

        setHardReset(prevState => {return !prevState});
        
    }, [activeCategories.length]);

  return (

    <div className={styles.container}>

        <div className={styles.paginator}>

            <div className={styles.prev} data-enable={paginator >1 ? "true" :"false"} onClick={paginator >1 ? ()=>{ setpaginator(paginator - 1)} : undefined}>

                <svg width="63" height="50" viewBox="0 0 63 50">
                    <defs>
                        <clipPath id="clip-path">
                        <rect width="18.57" height="32.499" fill="none"/>
                        </clipPath>
                    </defs>
                    <g transform="translate(924 556) rotate(180)">
                        <g transform="translate(861 506)">
                        <g>
                            <g fill="#eee" stroke="#000" strokeWidth="2">
                            <rect width="63" height="50" rx="25" stroke="none"/>
                            <rect x="1" y="1" width="61" height="48" rx="24" fill="none"/>
                            </g>
                        </g>
                        </g>
                        <g transform="translate(885 515)">
                        <g clipPath="url(#clip-path)">
                            <path d="M2.361,32.5a2.538,2.538,0,0,1-.4-.025,2.369,2.369,0,0,1-.5-.154A2.2,2.2,0,0,1,.4,31.365a2.1,2.1,0,0,1-.26-.712,2.4,2.4,0,0,1-.021-.664A2.272,2.272,0,0,1,.8,28.606q.415-.416.828-.834l.93-.934c.254-.256.506-.512.759-.768l.527-.529.962-.97.739-.743.76-.767.907-.912.782-.79.705-.71.761-.767.762-.765.76-.767.705-.71.771-.778.493-.5L13,16.311c.082-.081.088-.107,0-.193-.114-.111-.225-.224-.337-.336l-.81-.806-.463-.458-.878-.874L9.6,12.739l-.777-.773-.768-.759-.529-.526-.811-.806-.732-.727L5.229,8.4l-.766-.762L3.7,6.877l-.743-.739-.767-.76-.742-.74c-.158-.157-.316-.312-.473-.47C.834,4.034.7,3.9.569,3.761A2.152,2.152,0,0,1,.055,2.755,2.259,2.259,0,0,1,.07,1.7a2.167,2.167,0,0,1,.247-.6A2.248,2.248,0,0,1,1.421.16,2.2,2.2,0,0,1,2.256,0a2.544,2.544,0,0,1,.566.065,2.131,2.131,0,0,1,.255.086,2.109,2.109,0,0,1,.616.355c.094.077.181.163.267.249q.525.519,1.049,1.04c.176.175.352.352.528.527s.37.363.554.546c.169.168.337.337.506.5l.517.515L7.5,4.27l.509.5.271.268.846.838c.251.25.5.5.753.752.18.179.363.356.543.535s.344.345.517.516.362.357.542.536.344.344.517.516l.35.346.237.235c.251.25.5.5.753.752s.505.5.757.747.5.5.754.751l.745.737c.18.179.359.36.539.538l.745.737q.383.38.765.762c.079.078.155.159.237.235a2.172,2.172,0,0,1,.26.3,2.14,2.14,0,0,1,.279.515,2.348,2.348,0,0,1,.143.6,2.021,2.021,0,0,1-.008.491,2.592,2.592,0,0,1-.1.448,2.123,2.123,0,0,1-.4.717c-.069.081-.14.159-.214.234-.253.256-.507.511-.761.766l-.738.744-.783.789-.739.743-.961.971-.482.484-.57.576-.55.55-.5.507-.549.552-.334.339c-.1.1-.2.2-.3.3l-.258.259-.96.971c-.246.248-.493.495-.74.743s-.491.5-.737.745-.5.5-.751.753c-.175.176-.349.355-.524.531s-.359.359-.539.539l-.235.236-.638.642-.736.746c-.145.147-.29.294-.436.44a2.581,2.581,0,0,1-.47.377c-.05.03-.1.059-.153.085A2.7,2.7,0,0,1,3,32.417a1.666,1.666,0,0,1-.483.082H2.361" transform="translate(0 0)" fill="#1a1818"/>
                        </g>
                        </g>
                    </g>
                </svg>

            </div>

            <div className={styles.next} data-enable={(paginator * cardstoshow) < activeStores.length ? "true" :"false"} onClick={(paginator * cardstoshow) < activeStores.length ? ()=>{ setpaginator(paginator + 1)} : undefined}>

                <svg width="63" height="50" viewBox="0 0 63 50">
                    <defs>
                        <clipPath id="clip-path">
                        <rect width="18.57" height="32.499" fill="none"/>
                        </clipPath>
                    </defs>
                    <g transform="translate(-861 -506)">
                        <g transform="translate(861 506)">
                        <g>
                            <g fill="#eee" stroke="#000" strokeWidth="2">
                            <rect width="63" height="50" rx="25" stroke="none"/>
                            <rect x="1" y="1" width="61" height="48" rx="24" fill="none"/>
                            </g>
                        </g>
                        </g>
                        <g transform="translate(885 515)">
                        <g clipPath="url(#clip-path)">
                            <path d="M2.361,32.5a2.538,2.538,0,0,1-.4-.025,2.369,2.369,0,0,1-.5-.154A2.2,2.2,0,0,1,.4,31.365a2.1,2.1,0,0,1-.26-.712,2.4,2.4,0,0,1-.021-.664A2.272,2.272,0,0,1,.8,28.606q.415-.416.828-.834l.93-.934c.254-.256.506-.512.759-.768l.527-.529.962-.97.739-.743.76-.767.907-.912.782-.79.705-.71.761-.767.762-.765.76-.767.705-.71.771-.778.493-.5L13,16.311c.082-.081.088-.107,0-.193-.114-.111-.225-.224-.337-.336l-.81-.806-.463-.458-.878-.874L9.6,12.739l-.777-.773-.768-.759-.529-.526-.811-.806-.732-.727L5.229,8.4l-.766-.762L3.7,6.877l-.743-.739-.767-.76-.742-.74c-.158-.157-.316-.312-.473-.47C.834,4.034.7,3.9.569,3.761A2.152,2.152,0,0,1,.055,2.755,2.259,2.259,0,0,1,.07,1.7a2.167,2.167,0,0,1,.247-.6A2.248,2.248,0,0,1,1.421.16,2.2,2.2,0,0,1,2.256,0a2.544,2.544,0,0,1,.566.065,2.131,2.131,0,0,1,.255.086,2.109,2.109,0,0,1,.616.355c.094.077.181.163.267.249q.525.519,1.049,1.04c.176.175.352.352.528.527s.37.363.554.546c.169.168.337.337.506.5l.517.515L7.5,4.27l.509.5.271.268.846.838c.251.25.5.5.753.752.18.179.363.356.543.535s.344.345.517.516.362.357.542.536.344.344.517.516l.35.346.237.235c.251.25.5.5.753.752s.505.5.757.747.5.5.754.751l.745.737c.18.179.359.36.539.538l.745.737q.383.38.765.762c.079.078.155.159.237.235a2.172,2.172,0,0,1,.26.3,2.14,2.14,0,0,1,.279.515,2.348,2.348,0,0,1,.143.6,2.021,2.021,0,0,1-.008.491,2.592,2.592,0,0,1-.1.448,2.123,2.123,0,0,1-.4.717c-.069.081-.14.159-.214.234-.253.256-.507.511-.761.766l-.738.744-.783.789-.739.743-.961.971-.482.484-.57.576-.55.55-.5.507-.549.552-.334.339c-.1.1-.2.2-.3.3l-.258.259-.96.971c-.246.248-.493.495-.74.743s-.491.5-.737.745-.5.5-.751.753c-.175.176-.349.355-.524.531s-.359.359-.539.539l-.235.236-.638.642-.736.746c-.145.147-.29.294-.436.44a2.581,2.581,0,0,1-.47.377c-.05.03-.1.059-.153.085A2.7,2.7,0,0,1,3,32.417a1.666,1.666,0,0,1-.483.082H2.361" transform="translate(0 0)" fill="#1a1818"/>
                        </g>
                        </g>
                    </g>
                </svg>
            </div>

        </div>

        <div className={styles.content}>
           
                {activeStores ? 
            
                    activeStores.map((store,key) => {
                    return ((key+1) <= (cardstoshow * paginator)) && ((key+1) > (cardstoshow * (paginator - 1))) ? 
                    
                    <div className={styles.store} key={store.ID}>
                        
                        <StoreCard store={store} steps={steps} setSteps={setSteps} stepHandler={stepHandler}/>

                    </div>
                :null
                })
        
                :null} 
            
        </div>

    </div>
  )
}

export default List