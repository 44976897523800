import React from 'react';
import imgLoader from '../../assets/logo.svg'

import styles from './Loader.module.scss';

const Loader = ({active}) => {


    let loaderStyles = active ? [styles.Container] : [styles.Container, styles.Loaded];

    return (

        <section className={loaderStyles.join(' ')}>
            <div className={styles.ImageWrapper}>
                <img src={imgLoader} alt="loader" />
            </div>
        </section>
    )
}

export default Loader;