import React from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import App from './App';


const TangramRoutes = () => {
    return(
        
        <BrowserRouter basename='/'>
            <Routes>
                <Route path='/location1' element={<App location="location_1"/>}/>
                <Route path='/location2' element={<App location="location_2"/>}/>
                <Route path='/location3' element={<App location="location_3"/>}/>
                <Route path='/location4' element={<App location="location_4"/>}/>
                <Route path='/location5' element={<App location="location_5"/>}/>
                <Route path='/location6' element={<App location="location_6" position="landscape"/>}/>

                <Route path='*' element={<Navigate to='/location1' replace />}/>

            </Routes>
        </BrowserRouter>
    ) 
}

export default TangramRoutes