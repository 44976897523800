import styles from "./Map.module.scss"
import React, {useState} from 'react'
import icon from '../../assets/floor_icon.svg'
import iconmini from '../../assets/iconmini.svg'



const Map = ({
    
    data,
    setView,
    view,
    activeCategories,
    clickedStoreHandler,
    clickedStore,
    whereActive,
    setWhereActive,
    currentMap,
    setCurrentMap,
    steps, 
    setSteps,
    setActiveCategories,
    setHardReset,
    location
}) => {

    const whereActiveHandler = () => {

        setWhereActive(true);
        setCurrentMap(data.kiosks[location].floor)
        clickedStoreHandler("clear")
        
    }

    const calcposition = () => {
        if (data.kiosks[location].marker_position) {
           let left = data.kiosks[location].marker_position.split(",")[0];
           let right = data.kiosks[location].marker_position.split(",")[1];

           if (window.innerWidth > window.innerHeight) {

            return {transform: `translate(calc(-${left} + (50.25vw / 2)), calc(-${right} + (38.1vw / 2)))`}
               
           } else {

            return {transform: `translate(calc(-${left} + (100vw / 2)), calc(-${right} + (75.92vw / 2)))`}

           }
        } else {
            return null
        }
    }

  return data ? 

    data.kiosks.first_floor_image && data.kiosks.ground_floor_image ?


    <div className={styles.container} data-reverse={view === "reverse" ? "true" : "false"}>
        <div className={styles.MapFrame}>
            <div className={styles.mapa} data-floor={currentMap} data-where={whereActive ? "true" : "false"} data-reverse={view === "reverse" ? "true" : "false"} data-active-store={clickedStore ? "true" : "false"}>           
                <div className={styles.mapContainer} style={whereActive ? calcposition():null}>
                    {currentMap === "first" || currentMap === "food"  ? 
                        <img src={data.kiosks.first_floor_image.url} alt= "mapa2" data-mapa="first"/>   
                    :
                        <img src={data.kiosks.ground_floor_image.url} alt="mapa1" data-mapa="ground"/>
                    }
                    {data.kiosks[location].marker_position && data.kiosks[location].floor === currentMap ? 
                        <div className={styles.market} 
                            style={{left:data.kiosks[location].marker_position.split(",")[0],
                                    top:data.kiosks[location].marker_position.split(",")[1] }}>
                            <svg width="18.578" height="18.578" viewBox="0 0 18.578 18.578">
                                <g>
                                <circle cx="9.289" cy="9.289" r="9.289" transform="translate(0 0)" fill="red"/>
                                <path d="M5.693,0l5.693,9.589H0Z" transform="translate(3.596 3.596)" fill="#fff"/>
                                </g>
                            </svg>
                        </div>
                    :null}
                </div>

                {data.stores ?                 
                    <div className={styles.stores}>
                        <svg>
                            {data.stores.map(store => {

                                let activeFlag      = false;
                                let clickFlag       = false;
                                let clickFlagAlt    = [false, false];
                                let formatedMap     = null;

                                if (store.categories && clickedStore === null) {
                                    store.categories.map(category => {
                                        if(activeCategories.includes(category.slug)) {
                                            activeFlag = true;
                                        }
                                        return false;
                                    })
                                }

                                if (clickedStore) {

                                    if (clickedStore.ID === store.ID) {
                                        activeFlag = true
                                    }
                                }

                                if (store.content.map_location.floor === currentMap || (store.content.map_location.floor === "first" && currentMap === "food")) {
                                    clickFlag = true;
                                }

                                if (store.content.map_location.floor === "both") {
                                    clickFlagAlt = currentMap === "first" ? [true, false] : [false, true];
                                }

                                if( store.content.map_location.floor !== "both" && store.map) {
                                    formatedMap = (
                                        <g 
                                            className={styles.store}
                                            dangerouslySetInnerHTML={{__html:store.map}} 
                                            key={store.ID} 
                                            onClick={()=>{clickedStoreHandler(store.ID)}}
                                            data-visible={activeFlag ? "true" : "false"}
                                            data-click={clickFlag ? "true" : "false"}
                                        />
                                    )
                                }

                                if( store.content.map_location.floor === "both" && store.multiple_map) {
                                    formatedMap = (
                                        <g key={store.ID}>
                                            <g 
                                                className={styles.store}
                                                dangerouslySetInnerHTML={{__html:store.multiple_map[0]}}  
                                                onClick={()=>{clickedStoreHandler(store.ID)}}
                                                data-visible={activeFlag ? "true" : "false"}
                                                data-click={clickFlagAlt[0] ? "true" : "false"}
                                            />
                                            <g 
                                                className={styles.store}
                                                dangerouslySetInnerHTML={{__html:store.multiple_map[1]}} 
                                                onClick={()=>{clickedStoreHandler(store.ID)}}
                                                data-visible={activeFlag ? "true" : "false"}
                                                data-click={clickFlagAlt[1] ? "true" : "false"}
                                            />
                                        </g>
                                    )
                                }

                                return formatedMap ? formatedMap :null
                            })}
                        </svg>
                    </div>
                :null}
            </div>
            <div className={styles.zoomfade}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>

        {clickedStore !== null ? 
            
            <div className={styles.infoContainer} data-reverse={view === "reverse" ? "true" : "false"}>
            
                {clickedStore.title ? 
                    <div className={styles.title} dangerouslySetInnerHTML={{__html:clickedStore.title }}/>
                :null}
                
                <div className={styles.cathorario}>
                    {clickedStore.content.main_category ?
                        <div className={styles.category}>
                            <span dangerouslySetInnerHTML={{__html:clickedStore.content.main_category.name}}/>
                            <span dangerouslySetInnerHTML={{__html:clickedStore.content.map_location.floor === "ground" ? " - Level 1" : " - Level 2"}}/>
                        </div>
                    :null}

                    {clickedStore.content.schedule ?
                        <div className={styles.horario} dangerouslySetInnerHTML={{__html:clickedStore.content.schedule}}/>
                    :null}
                </div>
            
            </div>
        
        :null}

        {whereActive ?

            <div className={styles.buttonClose} onClick={()=>{setWhereActive(false)}}>

                <svg width="16.7" height="16.65" viewBox="0 0 16.7 16.65">
                    <path d="M8.35-16.65H3.7L.225-11.3-3.2-16.65H-8.175l5.7,8.325L-8.3,0h4.65l3.6-5.45L3.45,0H8.4L2.65-8.475Z" transform="translate(8.3 16.65)"/>
                </svg>

            </div>
        
        : currentMap !== "food" ? 
        
            <div 
            className={styles.where} 
            data-active={view === "reverse" ? "true" : "false"} 
            onClick={()=>{
                whereActiveHandler();
                setSteps(0);
                setActiveCategories([]);
                setHardReset(prevState => {return !prevState})
            }}>

                <svg width="29" height="29" viewBox="0 0 29 29">
                    <g transform="translate(-384 -1027)">
                        <circle cx="14.5" cy="14.5" r="14.5" transform="translate(384 1027)"/>
                        <path d="M9.5,0,19,16H0Z" transform="translate(389 1032)" fill="#fff"/>
                    </g>
                </svg>

                <div className={styles.textwhere}>Where Am I?</div>

            </div>
        :null

        }

        {steps === 1 ?

            <div className={styles.scalator1} data-scalator={data.kiosks[location].floor}>

                <div className={styles.instruction}>Take the escalator to the {data.kiosks[location].floor === "ground" ? "first" : "ground"} floor</div>

                <div className={styles.instruction2} onClick={()=>{setCurrentMap(data.kiosks[location].floor === "ground" ? "first" : "ground");setSteps(0)}}>

                    <svg width="20" height="20" viewBox="0 0 20 20">
                        <g transform="translate(0 0.345)">
                            <path d="M14.572,5.286H13.347a3.061,3.061,0,1,0-6.123,0H6a4.286,4.286,0,1,1,8.572,0Z" transform="translate(-2.326 -0.388)"/>
                            <path d="M16.467,11.123a1.8,1.8,0,0,0-.9.245,1.837,1.837,0,0,0-1.549-.857,1.8,1.8,0,0,0-.9.245,1.837,1.837,0,0,0-2.161-.747V6.837h0a1.837,1.837,0,1,0-3.674,0v6.8L5.918,12.7h0a1.794,1.794,0,0,0-1.084-.355,1.837,1.837,0,0,0-1.3,3.141l4.9,4.469a3.771,3.771,0,0,0,2.522.961h3.061A4.286,4.286,0,0,0,18.3,16.633V12.959A1.837,1.837,0,0,0,16.467,11.123Zm.612,5.51a3.061,3.061,0,0,1-3.061,3.061H10.956a2.553,2.553,0,0,1-1.69-.612L4.4,14.612a.611.611,0,0,1,.8-.918l3.306,2.265V6.837a.612.612,0,0,1,1.225,0h0v6.735h1.225V11.735a.612.612,0,1,1,1.225,0v1.837h1.225V12.347a.612.612,0,1,1,1.225,0v1.225h1.225v-.612a.612.612,0,0,1,1.225,0Z" transform="translate(-1.16 -1.939)"/>
                            <rect data-name="&lt;Transparent Rectangle&gt;" width="20" height="20" transform="translate(0 -0.345)" fill="none"/>
                        </g>
                    </svg>

                    <div className={styles.text}>Touch Here To continue</div>

                </div>

            </div>
        
        :null}

        {clickedStore !== null ? 
            
            <div className={styles.clear} data-active={view === "reverse" ? "true" : "false"} onClick={()=>{clickedStoreHandler("clear"); setSteps(0)}}>

            <svg width="17.321" height="17.691" viewBox="0 0 17.321 17.691">
                <g transform="translate(-6523.07 -7290.261) rotate(45)">
                <line x2="22.495" transform="translate(9768.5 542.486)" fill="none" stroke="#000" strokeWidth="2"/>
                <line x2="22.495" transform="translate(9780.01 531.5) rotate(90)" fill="none" stroke="#000" strokeWidth="2"/>
                </g>
            </svg>

            <div className={styles.textclear}>Clear</div>

            </div>
        
        :null}


        <div className={styles.move} onClick={()=>{setView(view === "map" ? "reverse" :"map")}} data-active={view === "reverse" ? "true" : "false"}>

            <svg viewBox="0 0 25.403 31.463">
                <g transform="translate(-47.38)">
                    <path d="M179.924,6.2a3.1,3.1,0,1,0-3.1-3.1A3.1,3.1,0,0,0,179.924,6.2Z" transform="translate(-121.165)"/>
                    <path d="M63.551,209.651a6.853,6.853,0,1,1-9.343-8.165V198.58a9.583,9.583,0,1,0,11.455,13.189l-1.082-2.127Z" transform="translate(0 -185.88)"/>
                    <path d="M199.168,137.246l-4.153-8.165a1.818,1.818,0,0,0-1.622-1h-5.319v-1.213h4.578a1.432,1.432,0,0,0,1.366-1.366,1.365,1.365,0,0,0-1.366-1.365h-4.578v-4.114a2.731,2.731,0,1,0-5.461,0v8.913a2.786,2.786,0,0,0,2.786,2.786h6.878l3.647,7.17a1.82,1.82,0,1,0,3.245-1.651Z" transform="translate(-126.584 -109.795)"/>
                </g>
            </svg>

            <div className={styles.textmove}>Move {view === "reverse" ? "Up" : "Down"}</div>

        </div>

        <div className={styles.selector} onClick={()=>{setSteps(0)}}>

            <div className={currentMap === "first" ? `${styles.item} ${styles.active}`:styles.item} data-floor="first" onClick={ () => {setCurrentMap("first"); setWhereActive(false)}}>

                <div className={styles.icon}>
                    <img src={icon} alt="icon"/>
                </div>

                <div className={styles.text}>First Floor</div>

            </div>

            <div className={currentMap === "ground" ? `${styles.item} ${styles.active}`:styles.item} data-floor="ground"  onClick={ () => {setCurrentMap("ground"); setWhereActive(false)}}>
                
                <div className={styles.icon}>
                    <img src={icon} alt="icon"/>
                </div>

                <div className={styles.text}>Ground Floor</div>
                
            </div>

            <div className={currentMap === "food" ? `${styles.item} ${styles.active}`:styles.item} data-floor="food"  onClick={ () => {setCurrentMap("food"); setWhereActive(false); clickedStoreHandler("clear")}}>

                <div className={styles.iconmini}>
                    <img src={iconmini} alt="icon"/>
                </div>

                <div className={styles.textfood}>Food Hall</div>                
            </div>
        </div>
    </div>
  :null :null
}

export default Map